<template>
  <div class="ddj-offer">
    <div class="main-Title bgff"><h2>数据列表</h2></div>
    <div class="mainBox">
      <el-card shadow="hover">
        <el-checkbox-group v-model="tagName">
          <el-checkbox label="os_version">os_version</el-checkbox>
          <el-checkbox label="brand">brand</el-checkbox>
          <el-checkbox label="source">source</el-checkbox>
          <el-checkbox label="language">language</el-checkbox>
          <el-checkbox label="active_time">active_time</el-checkbox>
        </el-checkbox-group>
      </el-card>
      <el-card shadow="hover">
        <div align="center" class="mb10" style="font-size: 16px;"
          >共有设备数: {{ resData.deviceAllTotal }} 条，安装数:
          {{ resData.deviceInstallTotal }} 条，安装率: {{ resData.installRate }}</div
        >
        <div align="center" class="mb10" style="font-size: 16px;"
          >country: {{ country }} os: {{ os }}</div
        >
        <el-table
          :data="list"
          v-loading="loading.list"
          ref="listTableRef"
          style="width:100%;"
          height="70vmin"
          size="mini"
          border
          highlight-current-row
        >
          <el-table-column label="标签" prop="tagNameAndValue"></el-table-column>
          <el-table-column label="安装数" prop="installRate"></el-table-column>
          <el-table-column label="设备数" prop="deviceAll"></el-table-column>
          <el-table-column label="安装率" prop="deviceInstall"></el-table-column>
          <el-table-column label="标签设备占比" prop="tagRate"></el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <!-- <div v-if="isShowPage" class="pagination-container pt-10" align="center">
          <el-pagination
            :page-size="pages.pageSize"
            :total="pagesTotal"
            background
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
          />
        </div> -->
      </el-card>
    </div>
  </div>
</template>

<script>
  import ddjSearchViewCon from '../../../controllers/DDJ/v3/data/searchView';
  export default {
    name: 'ddjSearchView',
    ...ddjSearchViewCon,
  };
</script>

<style></style>
